import { client } from '../axios/interceptors';

export const recommendationService = {
  getRecommendations,
  getSimilarTitles
};

async function getRecommendations (selectedModel, selectedSite, selectedAppId, perPage = 50, grades = [], languages = [], fromPubYear = '', toPubYear = '', reviewCount = '', source = 'all') {
  const uri = getRecommendationUri(selectedModel, selectedSite, selectedAppId, perPage, grades, languages, fromPubYear, toPubYear, reviewCount, source);
  const response = await client.get(uri);
  return response.data;
}

function getRecommendationUri (selectedModel, selectedSite, selectedAppId, perPage, grades, languages, fromPubYear, toPubYear, reviewCount, source) {
  const gradesParam = grades.includes('all') ? '' : `&grades=${grades.join(',')}`;
  const languagesParam = languages.includes('all') ? '' : `&langs=${languages.join(',')}`;
  const fromPubYearParam = fromPubYear === 0 ? '' : `&from_pub_year=${fromPubYear}`;
  const toPubYearParam = toPubYear === 0 ? '' : `&to_pub_year=${toPubYear}`;
  const reviewCountParam = reviewCount === 0 ? '' : `&review_count=${reviewCount}`;
  const sourcesParam = source === 'all' ? '' : `&sources=${source}`;
  return `/v2/apps/${selectedAppId}/sites/${selectedSite}/recs/titles/new?raw=true&model_guid=${selectedModel.guid}&per_page=${perPage}${gradesParam}${languagesParam}${fromPubYearParam}${toPubYearParam}${reviewCountParam}${sourcesParam}`;
}

async function getSimilarTitles (isbn_13, type, siteGuid, fscCustomerNo) {
  const [vector_type, db] = type.split('_');
  const response = await client.get(`/v2/apps/12345/sites/${siteGuid}/recs/titles/similar`, {
    params: { isbn_13, fsc_customer_no: fscCustomerNo, type: vector_type, db }
  });
  return response.data;
}
